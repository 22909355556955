import AuthContext from '../../AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { TenantSelectorComponent } from './TenantSelector';
import './Header.css';
import { GKBilling } from 'gkshared';
import { useHistory } from 'react-router-dom';
const gkb = new GKBilling(process.env.REACT_APP_APPLICATION_ID || '', "/", true);

export interface HeaderComponentProps {
    onTenantChange: any;
}

export const HeaderComponent = ({ onTenantChange }: HeaderComponentProps) => {
    const auth = useContext(AuthContext);
    const [tenants, setTenants] = useState<any>(null);
    const [selectedTenant, setSelectedTenant] = useState<any>(0);
    const [tenantsLoading, setTenantsLoading] = useState<any>(true);
    const [currentUser, setCurrentUser] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        if (auth && auth.email) {
            if (currentUser !== auth.email) {
                setCurrentUser(auth.account.Name);
            }
            if (auth?.applications) {
                const applications = auth.applications
                    .filter((ap: any) => (ap.roles.includes('admin') || ap.roles.includes('superadmin')))
                    .map((ap: any) => ({ label: ap.name, value: ap }));
                setTenants(applications);
                handleTenantChange(applications[0]);
                setTenantsLoading(false);
            }
        }
    }, [auth.email]);

    const handleTenantChange = (t: any) => {
        localStorage.setItem('lastTenantId', JSON.stringify(t));
        setSelectedTenant(t);
        onTenantChange(t);
    };

    const handleLogout = async () => {
        setCurrentUser(null);
        await gkb.Logout();
        window.location.reload()
        return
    }

    let role = '';
    if (auth?.roles && auth.roles.length > 0) {
        role = auth?.roles[0] === 'superadmin' ? 'Superadmin' : 'admin';
    }
    return (
        <header className="header bg-white py-4 px-4 shadow mb-1 absolute top-0 left-0 w-full h-20 z-10">
            <div className="header-content flex items-center flex-row ">
                <form>
                    <TenantSelectorComponent
                      tenants={tenants}
                      selectedTenant={selectedTenant}
                      isLoading={tenantsLoading}
                      onChange={handleTenantChange}
                      value={selectedTenant}
                    />
                    <div className="flex md:hidden">
                        <a
                          href="#"
                          className="flex items-center justify-center h-10 w-10 border-transparent"
                        >
                            <svg
                              className="h-6 w-6 text-gray-500"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </a>
                    </div>
                </form>
                <a
                  href="/"
                  className="flex items-center justify-center h-10 border-transparent ml-2"
                >Volver a la tienda</a>
                <div className="flex ml-auto">
                    <a href={"#"} className="flex flex-row items-center">
                        <img
                          src={auth?.account?.ImageUrl ?? ""}
                          alt={""}
                          className="h-10 w-10 bg-gray-200 border rounded-full"
                        />
                        <span className="flex flex-col ml-2">
                            <span className="truncate w-40 font-semibold tracking-wide leading-none">
                                {currentUser}
                            </span>
                            <span className="truncate w-40 text-gray-500 text-xs leading-none mt-1">
                                {role}
                            </span>
                        </span>
                    </a>
                    <button
                      className="primary shadow-xl"
                      onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </header>
    );
};
