import React from 'react';
import TenantContext from '../../TenantContext';
import { GetResourceFromName } from '../../models/ResourceFactory';
import { Resource } from '../../models/Resource';
import { Button } from '../buttons/Button';
import { PageWrapper } from '../../pages/PageWrapper';
import { NotificationManager } from 'react-notifications';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ResourceEditorSection } from './ResourceEditorSection';
import { Action, ResourceEditor } from '../../models/ResourceSettings';
import { RenderPropSticky } from 'react-sticky-el';
import { LocalizedString } from '../../models/LocalizedString';

interface Props extends RouteComponentProps {
    resource: string;
    id: number;
}

type State = {
    loading: boolean;
    deleting: boolean;
    currentId: number;
    resource: Resource | null;
    editor?: ResourceEditor;
    resourceNames?: string[];
    resetting: boolean;
};

class _LocalizedTextResourceEditorComponent extends React.Component<
    Props,
    State
> {
    static contextType = TenantContext;
    constructor(props: Props) {
        super(props);
        const res = GetResourceFromName(props.resource, this.context);

        this.state = {
            resource: null,
            loading: false,
            deleting: false,
            currentId: this.props.id,
            resourceNames: res?.GetNames(),
            resetting: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.handleOnAction = this.handleOnAction.bind(this);
    }

    async loadData() {
        this.setState({ loading: true });
        const { currentId } = this.state;
        const { resource } = this.props;

        // @ts-ignore
        const np = new GetResourceFromName(resource, this.context);
        if (np) {
            np.Retrieve(currentId).then(() => {
                this.setState({ resource: np, loading: false });
            });
        }
        this.setState({ loading: false });
    }

    refreshPath(id: number) {
        const newPath = this.props.location.pathname.replaceAll(
            '/new',
            `/${id}`
        );
        this.props.history.replace(newPath);
    }

    backPath() {
        const newPath = this.props.location.pathname.replaceAll(
            `/${this.state.currentId}`,
            ``
        );
        this.props.history.replace(newPath);
    }

    handleOnSave = async () => {
        const response = await this.save();
        this.setState({ currentId: response.ID }, () => {
            this.refreshPath(response.ID);
        });
    };

    handleOnSaveAndOther = async () => {
        await this.save();
        this.resetContent();
    };

    save = async () => {
        const { resource } = this.state;
        this.setState({ loading: true });
        const response = await resource?.Save();
        this.setState({ loading: false });
        NotificationManager.success('Save successfully');
        return response;
    };

    resetContent = () => {
        const { resource, id } = this.props;
        const np = GetResourceFromName(resource, this.context);

        this.setState(
            {
                resource: null,
                loading: true,
                resetting: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        resource: np,
                        resetting: false,
                        loading: false,
                    });
                }, 300);
            }
        );
    };

    handleOnDelete = async () => {
        const { resource } = this.state;
        this.setState({ deleting: true });

        await resource?.Delete();
        this.setState({ deleting: false });
        NotificationManager.success('Delete successfully');
        this.backPath();
    };

    handleOnChange = (event: any, field: string) => {
        const { resource } = this.state;
        const tenant = this.context;
        const np = GetResourceFromName(
            this.props.resource,
            tenant,
            resource ?? undefined
        );
        const value =
            field !== 'Active'
                ? event?.currentTarget?.value ?? event
                : event?.currentTarget.checked;

        np?.Set(field, value);
        this.setState({ resource: np });
    };

    handleOnAction = async (action: Action) => {
        const { resource } = this.state;
        const rs = resource as LocalizedString;
        if (action.action === 'translate') {
            const tr = await rs.Translate();
            tr.en &&
                this.handleOnChange({ currentTarget: { value: tr.en } }, 'en');
            tr.fr &&
                this.handleOnChange({ currentTarget: { value: tr.fr } }, 'fr');
            tr.de &&
                this.handleOnChange({ currentTarget: { value: tr.de } }, 'de');
            tr.nl &&
                this.handleOnChange({ currentTarget: { value: tr.nl } }, 'nl');
            tr.pt &&
                this.handleOnChange({ currentTarget: { value: tr.pt } }, 'pt');
        }
    };

    async componentDidMount() {
        const { resource, id } = this.props;
        const { currentId } = this.state;
        const tenant = this.context;

        const np = GetResourceFromName(resource, tenant);
        const resourceSettings = np?.GetResourceSettings();
        const editor = resourceSettings?.editor;
        if (editor) {
            this.setState({ editor });
        }

        if (currentId === 0) {
            this.setState({ resource: np });
        } else {
            await this.loadData();
        }
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        const { currentId } = this.state;
        if (currentId !== prevState.currentId) {
            await this.loadData();
        }
    }

    render() {
        const t = (t: string) => t;
        const {
            resource,
            loading,
            deleting,
            currentId,
            editor,
            resetting,
            resourceNames = ['', ''],
        } = this.state;
        return (
            <PageWrapper title="">
                {!resetting && (
                    <>
                        <RenderPropSticky
                            scrollElement=".gk-main-wrapper"
                            topOffset={0}
                            onFixedToggle={() => {
                                console.log('######## TOGGLE ');
                            }}
                        >
                            {({
                                isFixed,
                                wrapperStyles,
                                wrapperRef,
                                holderStyles,
                                holderRef,
                            }: any) => (
                                <div ref={holderRef} style={holderStyles}>
                                    <div
                                        ref={wrapperRef}
                                        style={{
                                            ...wrapperStyles,
                                            left: isFixed ? 226 : 'auto',
                                            width: isFixed
                                                ? 'calc(100% - 226px)'
                                                : 'calc(100%)',
                                        }}
                                        className={`w-full px-4  flex justify-between bg-white z-10 h-40${
                                            isFixed
                                                ? 'border py-4 shadow-xl'
                                                : 'my-4'
                                        }`}
                                    >
                                        <div className={'font-bold uppercase'}>
                                            <Link to={`/${resourceNames[1]}`}>
                                                <span className="underline cursor-pointer text-gray-600 hover:opacity-60">
                                                    {resourceNames[1]}
                                                </span>
                                            </Link>
                                            <span>{'  >  '}</span>
                                            {currentId > 0
                                                ? `${this.props.resource} #${currentId}`
                                                : `New ${this.props.resource}`}
                                        </div>
                                        <div className={'flex'}>
                                            <Button
                                                className={'mr-4'}
                                                style={'danger'}
                                                text={'Delete'}
                                                disabled={
                                                    loading || currentId === 0
                                                }
                                                onClick={this.handleOnDelete}
                                                loading={deleting}
                                            />
                                            <Button
                                                className={'mr-4'}
                                                style={'primary'}
                                                text={'Save'}
                                                disabled={
                                                    deleting ||
                                                    (editor?.isValid
                                                        ? !editor?.isValid(
                                                              resource
                                                          )
                                                        : false)
                                                }
                                                onClick={this.handleOnSave}
                                                loading={loading}
                                            />
                                            <Button
                                                style={'secondary'}
                                                text={'Save and other'}
                                                disabled={
                                                    deleting ||
                                                    (editor?.isValid
                                                        ? !editor?.isValid(
                                                              resource
                                                          )
                                                        : false)
                                                }
                                                onClick={
                                                    this.handleOnSaveAndOther
                                                }
                                                loading={loading}
                                            />
                                            {editor?.actions?.map((a) => {
                                                return (
                                                    <Button
                                                        className={'ml-4'}
                                                        style={'secondary'}
                                                        text={a.text}
                                                        onClick={() =>
                                                            this.handleOnAction(
                                                                a
                                                            )
                                                        }
                                                        loading={loading}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </RenderPropSticky>
                        <form>
                            <div className={`${loading ? 'opacity-30' : ''}`}>
                                {editor?.sections?.map((s: any) => (
                                    <ResourceEditorSection
                                        resource={resource}
                                        onChange={this.handleOnChange}
                                        section={s}
                                    />
                                ))}
                            </div>
                        </form>
                    </>
                )}
            </PageWrapper>
        );
    }
}

export const LocalizedTextResourceEditorComponent = withRouter(
    _LocalizedTextResourceEditorComponent
);
