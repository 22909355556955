import React, { ChangeEvent, ChangeEventHandler } from 'react';
import Select from "react-select";

type Item = {
    label: string;
    value: string;
};

export interface SelectInputProps {
    label?: string;
    value?: string;
    options: Item[];
    onChange: any;
}

export const SelectInput = ({
    label = '',
    value = '',
    options = [],
    onChange,
}: SelectInputProps) => {

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                >
                    {label}
                </label>
            )}
            <Select
                classNamePrefix="gk-select"
                options={options}
                isSearchable={false}
                isDisabled={false}
                onChange={(e) => {
                    onChange(e?.value ?? '');
                }}
                value={{
                    label: value?.toUpperCase(),
                    value: value,
                }}
            />        </>
    );
};
