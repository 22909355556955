import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LocalizedTextResourceEditorComponent } from './ResourceEditor/LocalizedStringResourceEditor';

type EditResourceState = {
    currentId: number;
};

interface EditResourceProps extends RouteComponentProps {
    resource: string;
}

class _LocalizedTextEditResourcePage extends React.Component<
    EditResourceProps,
    EditResourceState
> {
    constructor(props: EditResourceProps) {
        super(props);
        let id = (props.match.params as any).id;
        if (id === 'new') {
            id = 0;
        } else {
            id = parseInt(id, 10);
        }
        this.state = {
            currentId: id,
        };
    }

    render() {
        const { currentId } = this.state;
        const { resource } = this.props;
        return (
            <LocalizedTextResourceEditorComponent
                resource={resource}
                id={currentId}
            />
        );
    }
}

export const LocalizedTextEditResourcePage = withRouter(
    _LocalizedTextEditResourcePage
);
