import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from './routes.json';
import logo2 from '../../../assets/img/gk-logo1.png';
import AuthContext from '../../AuthContext';

export const SidenavComponent = () => {
    const location = useLocation();
    const auth = useContext(AuthContext);

    const renderRoute = (route: any) => {
        if(hasPermissions(route.role, auth?.roles || []) === false) {
            return null;
        }

        if (route.type === 'group') {
            return (
              <li className="">
                <span className="flex font-medium text-sm text-gray-300 my-4 uppercase bg-gray-600 py-2 px-2">
                    {route.name}
                </span>
              </li>
            );
        }

        const selected = location.pathname.startsWith(route.to);
        return (
            <li className="my-px px-4">
                <Link to={route.to}>
                    <a
                        href="#"
                        className={`flex flex-row items-center h-10 px-3 rounded-lg  ${
                            selected
                                ? 'bg-gray-100 text-gray-700'
                                : 'text-gray-300 hover:bg-gray-100 hover:text-gray-700'
                        }`}
                    >
                        <span className="ml-4">{route.name}</span>
                    </a>
                </Link>
            </li>
        );
    };

    return (
        <aside
            className="sidebar w-64 md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-gray-800"
            style={{ overflowY: 'auto', height: 'calc(100vh - 80px)' }}
        >
            <div className="sidebar-header flex items-center justify-center py-4">
                <div className="inline-flex">
                    <a href="#" className="inline-flex flex-row items-center">
                        <img src={logo2} height={60} width={60} />
                        <span className="leading-10 text-gray-100 text-2xl font-bold ml-2">
                            GK Billing
                        </span>
                    </a>
                </div>
            </div>
            <div className="sidebar-content py-6">
                <ul className="flex flex-col w-full">
                    {routes.map((r) => renderRoute(r))}
                </ul>
            </div>
        </aside>
    );
};

function hasPermissions(routeRole: string, userRoles: string[]) {
    if(!routeRole) {
        return true;
    }
    if(routeRole === "admin") {
        return userRoles.includes("admin") || userRoles.includes("superadmin")
    }

    if(routeRole === "superadmin") {
        return userRoles.includes("superadmin");
    }
    return false;
}