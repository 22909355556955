import { Resource } from './Resource';
import { ResourceSettings } from './ResourceSettings';
import { LocalizedString } from './LocalizedString';

export type TTutorial = Tutorial | null;

export type TutorialStep = {
    Name: string;
    Target: string;
    Content: LocalizedString;
};

export class Tutorial extends Resource {
    Name: string = '';
    Steps: TutorialStep[];

    constructor(tenantId: string, tutorial?: any) {
        super('tutorials', tenantId, tutorial?.ID ?? 0);
        this.SetBody(tutorial);
    }

    async Retrieve(id: number): Promise<void> {
        await super.Retrieve(id);
    }

    Set(field: string, value: string | number) {
        // @ts-ignore
        this[field] = value;
    }

    GetBody() {
        const body: any = super.GetBody();
        return body;
    }

    GetNames(): string[] {
        return ['tutorial', 'tutorials'];
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'tutorials',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Name',
                    field: 'Name',
                    sortable: true,
                    searchable: true,
                },

                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return (
                        resource?.Get('Name') !== '' &&
                        resource?.Get('Style') !== ''
                    );
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                name: 'Name',
                                field: 'Name',
                                type: 'input',
                                width: 6,
                                data: 'text',
                            },
                        ],
                    },
                ],
            },
        };
    }
}
