import React from 'react';
import './uploaders.css';
import { DropZone } from '../../dropZone/DropZone';

export interface ImageUploaderProps {
    value?: string;
    onChange: any;
}

export const ImageUploader = ({ value, onChange }: ImageUploaderProps) => {
    /*    useEffect(() => {
        setImage(value);
    }, [value]);*/

    const handleDropAccepted = (files: any) => {
        if (files.length > 0) {
            const blob = new Blob([files[0]], {
                type: files[0].type,
            });
            const url = URL.createObjectURL(blob);
            onChange && onChange(url);

            sessionStorage.setItem(url, files[0].name);
        }
    };

    return (
        <div className={'relative'}>
            <DropZone onDropAccepted={handleDropAccepted}>
                {value && (
                    <div
                        className={
                            'absolute h-full w-full flex justify-center items-center overflow-hidden'
                        }
                        style={{ borderRadius: 10 }}
                    >
                        <img
                            src={value}
                            alt="Product image"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                )}
                {!value && <p>Arrastra o haz click</p>}
            </DropZone>
        </div>
    );
};
