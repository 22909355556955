import React, { ChangeEvent, ChangeEventHandler } from 'react';

export interface TextInputProps {
    label?: string;
    value?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const CheckInput = ({
    label = '',
    value = false,
    onChange,
}: TextInputProps) => {
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
    };

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                >
                    {label}
                </label>
            )}
            <div  style={{height: 46, paddingTop: 10}}>
                <input
                    id="grid-name"
                    type='checkbox'
                    checked={value}
                    onChange={handleOnChange}
                    style={{width:24, height:24}}
                    className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear  `}
                />
            </div>
        </>
    );
};
