import React from 'react';
import './BgLoader.css';

type Props = {}

export default function bgloader({}: Props) {
    return (
        <div className="gk-loader">

        </div>
    );
}
