import { Resource } from './Resource';
import { ResourceSettings } from './ResourceSettings';

export type TLocalizedString = LocalizedString | null;

export class LocalizedString extends Resource {
    key: string = '';
    es?: string;
    en?: string;
    fr?: string;
    de?: string;
    nl?: string;
    pt?: string;

    constructor(tenantId: string, localizedString?: any) {
        super('texts', tenantId, localizedString?.ID ?? 0);
        this.SetBody(localizedString);
    }

    async Translate() {
        return await this.gkb.TranslateText(this.es);
    }

    GetNames(): string[] {
        return ['text', 'texts'];
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'texts',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Key',
                    field: 'key',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: 'Es',
                    field: 'es',
                    sortable: false,
                    searchable: true,
                },
                {
                    text: 'En',
                    field: 'en',
                    sortable: false,
                    searchable: true,
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return resource?.Get('key') !== '';
                },
                actions: [
                    {
                        text: 'Translate',
                        action: 'translate',
                        color: 'text-yellow-600',
                        icon: 'fal fa-globe',
                    },
                ],
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                name: 'Key',
                                field: 'key',
                                type: 'input',
                                width: 4,
                                data: 'text',
                            },
                            {
                                name: 'Es',
                                field: 'es',
                                type: 'input',
                                width: 12,
                            },
                            {
                                name: 'En',
                                field: 'en',
                                type: 'input',
                                width: 12,
                            },
                            {
                                name: 'Fr',
                                field: 'fr',
                                type: 'input',
                                width: 12,
                            },
                            {
                                name: 'De',
                                field: 'de',
                                type: 'input',
                                width: 12,
                            },
                            {
                                name: 'Nl',
                                field: 'nl',
                                type: 'input',
                                width: 12,
                            },
                            {
                                name: 'Pt',
                                field: 'pt',
                                type: 'input',
                                width: 12,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
