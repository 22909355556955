import React, {
    ChangeEvent,
    ChangeEventHandler,
    useEffect,
    useState,
} from 'react';
import { RichTextEditor } from '../richTextEditor/RichTextEditor';
import { EventHandler } from '@tinymce/tinymce-react/lib/es2015/main/ts/Events';

export interface LocalizedRichTextInputProps {
    label?: string;
    value?: any;
    onChange?: any;
}

const LanguageSelector = ({ lang, selected, onSelect }: any) => {
    return (
        <span
            className={`${
                selected === lang ? 'text-green-600 underline' : ''
            } ml-4 font-normal normal-case cursor-pointer text-base  hover:opacity-40`}
            onClick={() => onSelect(lang)}
        >
            {lang}
        </span>
    );
};

export const LocalizedRichTextInput = ({
    label = '',
    value = '',
    onChange,
}: LocalizedRichTextInputProps) => {
    const [currentLang, setCurrentLang] = useState('es');
    const [currentValue, setCurrentValue] = useState(value?.es || '');

    useEffect(() => {
        setCurrentValue(value ? value[currentLang] || '' : '');
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event);
        let nv = value ?? {};

        nv[currentLang] = event;

        onChange && onChange(nv);
    };
    const handleOnLangSelect = (lang: string) => {
        setCurrentLang(lang);
        setCurrentValue(value ? value[lang] || '' : '');
    };

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                >
                    {label}
                    <LanguageSelector
                        lang={'es'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />
                    <LanguageSelector
                        lang={'en'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />{' '}
                    <LanguageSelector
                        lang={'fr'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />
                    <LanguageSelector
                        lang={'de'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />
                    <LanguageSelector
                        lang={'nl'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />
                    <LanguageSelector
                        lang={'pt'}
                        selected={currentLang}
                        onSelect={handleOnLangSelect}
                    />
                </label>
            )}
            <RichTextEditor value={currentValue} onChange={handleOnChange} />
        </>
    );
};
