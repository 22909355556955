import React, { useState } from 'react';
import { CollapseUp } from './icons';

export const Section = ({
    children,
    title,
    collapsable = false,
    defaultCollapsed = false,
}: any) => {
    const [expanded, setExpanded] = useState(!defaultCollapsed);
    const toggleExpanded = () => {
        if (collapsable) {
            setExpanded(!expanded);
        }
    };
    return (
        <div className={`w-full rounded border mt-6 pb-6 shadow`}>
            <div
                className="transform -translate-y-3 translate-x-3 text-xl bg-white px-2 flex items-center cursor-pointer"
                style={{ width: 'fit-content' }}
                onClick={toggleExpanded}
            >
                {title}
                {collapsable && (
                    <span
                        className={`ml-2 icon-button ${
                            expanded
                                ? 'transition-transform transform rotate-0'
                                : 'transition-transform transform rotate-180'
                        }`}
                    >
                        {CollapseUp}
                    </span>
                )}
            </div>
            <div
                className={`transition-all duration-150 ease-in ${
                    expanded ? '' : 'h-0 overflow-hidden'
                }`}
            >
                {children}
            </div>
        </div>
    );
};
