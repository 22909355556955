import React, {useEffect, useState} from "react";
import {LocalizedTextInput} from "../../forms/inputs/LocalizedTextInput";

export const RSEFLocalizedInput = ({field, onChange, resource}:any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if(resource) {
            const aux = resource.Get(field.field)
            setValue(aux)
        }
    }, [resource])

    const handleOnChange = (nv:any) => {
        onChange(nv, field.field)
    }

    return <LocalizedTextInput label={field.name || field.field} value={value} onChange={handleOnChange}/>
}
