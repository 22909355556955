import { Resource } from './Resource';
import { Faq } from './Faq';
import { Legal } from './Legal';
import { Theme } from './Theme';
import { Product } from './Product';
import { LocalizedString } from './LocalizedString';
import { Subscription } from './Subscriptions';
import { Slide } from './Slide';
import { Section } from './Section';
import { Feature } from './Feature';
import { Tutorial } from './Tutorials';
import { Template } from "./Templates";
import { Account } from "./Accounts";

export function GetResourceFromName(
    name: string,
    tenantId: string,
    resource?: Resource
): Resource | null {
    switch (name) {
        case 'faqs':
        case 'faq':
            return new Faq(tenantId, resource);
        case 'legals':
        case 'legal':
            return new Legal(tenantId, resource);
        case 'themes':
        case 'theme':
            return new Theme(tenantId, resource);
        case 'product':
        case 'products':
            return new Product(tenantId, resource);
        case 'text':
        case 'texts':
            return new LocalizedString(tenantId, resource);
        case 'subscription':
        case 'subscriptions':
            return new Subscription(tenantId, resource);
        case 'slide':
        case 'slides':
            return new Slide(tenantId, resource);
        case 'section':
        case 'sections':
            return new Section(tenantId, resource);
        case 'feature':
        case 'features':
            return new Feature(tenantId, resource);
        case 'tutorial':
        case 'tutorials':
            return new Tutorial(tenantId, resource);
        case 'template':
        case 'templates':
            return new Template(tenantId, resource);
        case 'account':
        case 'accounts':
            return new Account(tenantId, resource);

    }

    return null;
}
