import React, {useEffect, useState} from "react";
import {SelectInput} from "../../forms/inputs/SelectInput";

export const RSEFSelect = ({field, onChange, resource}:any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if(resource && field.data) {
            const aux = resource.Get(field.field)
            for(let i = 0; i < field.data.length; i++) {
                if(aux === field.data[i].value) {
                    setValue(field.data[i].value);
                    break;
                }
            }
        }
    }, [resource])

    const handleOnChange = (nv:any) => {
        onChange(nv, field.field)
    }

    return <SelectInput label={field.name || field.field} value={value} onChange={handleOnChange} options={field.data}/>
}
