import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './PageWrapper.css';
import TenantContext from '../TenantContext';

export interface PageWrapperProps {
    title: string;
    loading?: boolean;
    children: any;
}

export const PageWrapper = ({
    title = '',
    loading = false,
    children,
}: PageWrapperProps) => {
    const [show, setShow] = useState(false);
    const [reloading, setReloading] = useState(false);
    const tenant = useContext(TenantContext);

    useEffect(() => {
        setShow(true);
    }, []);

    useEffect(() => {
        setReloading(true);
    }, [tenant]);

    useEffect(() => {
        if (reloading === true) {
            setReloading(false);
        }
    }, [reloading]);

    return (
        <CSSTransition timeout={300} classNames="page" unmountOnExit in={show}>
            <div
                className={`${
                    loading ? 'opacity-20' : ''
                } flex flex-col flex-grow overflow-y-auto overflow-x-hidden gk-main-wrapper`}
                style={{ height: 'calc(100vh - 78px)' }}
            >
                <h1 className="font-bold text-2xl text-gray-700 uppercase ml-4">
                    {title}
                </h1>
                {!reloading && tenant !== '' && (
                    <div className="flex flex-col flex-grow bg-white rounded m-4 shadow-2xl p-4">
                        {children}
                    </div>
                )}
            </div>
        </CSSTransition>
    );
};
