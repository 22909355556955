import { PageWrapper } from '../../PageWrapper';
import React from 'react';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter,
} from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { Section } from '../../../components/sections/Section';
import { Button } from '../../../components/buttons/Button';
import { BillingPeriod, Product, TProduct } from '../../../models/Product';
import { TextInput } from '../../../components/forms/inputs/TextInput';
import { DropDownInput } from '../../../components/forms/inputs/DropDownInput';
import { ImageUploaderInput } from '../../../components/forms/inputs/ImageUploaderInput';
import { Subsection } from '../../../components/sections/Subsection';
import Select from 'react-select';
import { LocalizedTextInput } from '../../../components/forms/inputs/LocalizedTextInput';
import { LocalizedRichTextInput } from '../../../components/forms/inputs/LocalizedRichTextInput';
import { GKUploader } from 'gkshared';
import TenantContext from '../../../TenantContext';
import Sticky, { RenderPropSticky } from 'react-sticky-el';
import { GetResourceFromName } from '../../../models/ResourceFactory';

const gku = new GKUploader(process.env.REACT_APP_APPLICATION_ID || '');

type EditProductState = {
    product: TProduct;
    loading: boolean;
    deleting: boolean;
    currentId: number;
};

interface EditProductProps extends RouteComponentProps {}

class _EditProductPage extends React.Component<
    EditProductProps,
    EditProductState
> {
    static contextType = TenantContext;

    constructor(props: EditProductProps) {
        super(props);

        let id = (props.match.params as any).id;
        if (id === 'new') {
            id = 0;
        } else {
            id = parseInt(id, 10);
        }

        this.state = {
            loading: true,
            deleting: false,
            product: null,
            currentId: id,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnPriceChange = this.handleOnPriceChange.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.handleAddNewPrice = this.handleAddNewPrice.bind(this);
    }

    async componentDidMount() {
        const tenant: any = _EditProductPage.contextType;
        const np = new Product(tenant);
        const { currentId } = this.state;

        if (currentId === 0) {
            this.setState({ product: np });
        } else {
            await this.loadData();
        }

        this.setState({ loading: false });
    }

    async componentDidUpdate(
        prevProps: EditProductProps,
        prevState: EditProductState
    ) {
        const { currentId } = this.state;
        if (currentId !== prevState.currentId) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });
        const { currentId } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('product', this.context);

        np.Retrieve(currentId as number).then(() => {
            this.setState({ product: np, loading: false });
        });
    }

    handleOnSave = async () => {
        const { product } = this.state;
        this.setState({ loading: true });

        if (product?.ImageUrl?.startsWith('blob:')) {
            const url = await gku.UploadBlob(product?.ImageUrl);
            product?.Set('ImageUrl', url);
        }

        if (product && product.Prices) {
            for (let i = 0; i < product?.Prices?.length || 0; i++) {
                const p = product?.Prices[i];
                if(p.Amount < p.Fee) {
                    p.Amount = p.Fee
                }
                if ((p?.ID || 0) < 0) {
                    p.ID = 0;
                }
            }
        }
        const response = await product?.Save();
        this.setState({
            loading: false,
            currentId: response.ID,
            product: Object.assign(product, { ...response }),
        });
        this.props.history.replace('/products/' + response.ID);
        NotificationManager.success('Save successfully');
    };

    handleOnDelete = async () => {
        const { product } = this.state;
        this.setState({ deleting: true });

        const response = await product?.Delete();
        this.setState({ deleting: false });
        this.props.history.replace('/products');
        NotificationManager.success('Save successfully');
    };

    handleOnChange = (event: any, field: string) => {
        const { product } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('product', this.context, product);
        const value =
            field !== 'Active'
                ? event?.currentTarget?.value ?? event
                : event?.currentTarget.checked;

        np.Set(field, value);
        this.setState({ product: np });
    };

    handleOnPriceChange = (field: string, priceID: number, value: any) => {
        const { product } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('product', this.context, product);

        for (let i = 0; i < np.Prices.length; i++) {
            if (np.Prices[i].ID === priceID) {
                if (field === 'Amount') {
                    // @ts-ignore
                    np.Prices[i][field] = isNaN(value) ? '' : value;
                } else {
                    // @ts-ignore
                    np.Prices[i][field] = value;
                }
            }
        }

        this.setState({ product: np });
    };

    handleAddNewPrice = () => {
        const { product } = this.state;
        const tenant: any = TenantContext;
        const np = new Product(tenant, product);
        if (!np.Prices) {
            np.Prices = [];
        }

        np.Prices.push({
            Active: true,
            Name: '',
            Interval: BillingPeriod.BillingPeriodMonthly,
            Currency: 'eur',
            ID: -Date.now(),
            IsNew: true,
            Expanded: true,
            StripeID: '',
        });

        this.setState({ product: np });
    };

    render() {
        const { product, loading, deleting, currentId } = this.state;
        const id = currentId;
        const t = (t: string) => t;

        return (
            <PageWrapper title="">
                <RenderPropSticky
                    scrollElement=".gk-main-wrapper"
                    topOffset={0}
                    onFixedToggle={() => {
                        console.log('######## TOGGLE ');
                    }}
                >
                    {({
                        isFixed,
                        wrapperStyles,
                        wrapperRef,
                        holderStyles,
                        holderRef,
                    }: any) => (
                        <div ref={holderRef} style={holderStyles}>
                            <div
                                ref={wrapperRef}
                                style={{
                                    ...wrapperStyles,
                                    left: 226,
                                    width: 'calc(100%)',
                                }}
                                className={`w-full px-4  flex justify-between bg-white z-10 h-40${
                                    isFixed ? 'border py-4 shadow-xl' : 'my-4'
                                }`}
                            >
                                <div className={'font-bold uppercase'}>
                                    <Link to={`/products`}>
                                        <span className="underline cursor-pointer text-gray-600 hover:opacity-60">
                                            products
                                        </span>
                                    </Link>
                                    <span>{'  >  '}</span>
                                    {currentId > 0
                                        ? `product #${currentId}`
                                        : `New product`}
                                </div>
                                <div className={'flex'}>
                                    <Button
                                        className={'mr-4'}
                                        style={'danger'}
                                        text={'Delete'}
                                        disabled={loading || currentId === 0}
                                        onClick={this.handleOnDelete}
                                        loading={deleting}
                                    />
                                    <Button
                                        style={'primary'}
                                        text={'Save'}
                                        disabled={deleting}
                                        onClick={this.handleOnSave}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </RenderPropSticky>
                <form>
                    <div className={`${loading ? 'opacity-30' : ''}`}>
                        <Section
                            title={'General'}
                            collapsable={false}
                            defaultCollapsed={false}
                        >
                            <div className="w-full px-4 my-4 flex items-center">
                                <div className="w-full lg:w-10/12 px-4 mt-6">
                                    <TextInput
                                        type={'text'}
                                        label={'product name'}
                                        value={product?.Name ?? ''}
                                        onChange={(ev) =>
                                            this.handleOnChange(ev, 'Name')
                                        }
                                    />
                                </div>
                                <div className="w-full px-4 my-4 flex">
                                    <div className="w-full lg:w-6/12 px-4 mt-6">
                                        <TextInput
                                          type={'text'}
                                          label={'sku'}
                                          value={product?.Sku ?? ''}
                                          disabled={true}
                                          onChange={(ev) =>
                                            this.handleOnChange(ev, 'Sku')
                                          }
                                        />
                                    </div>
                                <div className="w-full lg:w-2/12 px-4 mt-6">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                                        htmlFor="grid-name"
                                    >
                                        {t('Activo')}
                                    </label>
                                    <input
                                        className={
                                            'cursor-pointer form-checkbox mr-4'
                                        }
                                        type="checkbox"
                                        id="cbox2"
                                        checked={product?.Active}
                                        style={{ width: 18, height: 18 }}
                                        onChange={(ev) =>
                                            this.handleOnChange(ev, 'Active')
                                        }
                                    />
                                </div>
                            </div>

                                {/*<div className="w-full lg:w-6/12 px-4 mt-6">*/}
                                {/*    <DropDownInput*/}
                                {/*        label="Category"*/}
                                {/*        value={product?.Category ?? ''}*/}
                                {/*        resource={'categories'}*/}
                                {/*        onChange={(ev) =>*/}
                                {/*            this.handleOnChange(ev, 'Category')*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </Section>
                        <Section
                            title={'Product info'}
                            collapsable={true}
                            defaultCollapsed={false}
                        >
                            <div className="w-full  my-4 flex">
                                <div className="w-full lg:w-12/12 px-4 mt-6">
                                    <LocalizedTextInput
                                        type={'text'}
                                        label={'description'}
                                        value={product?.Description || {}}
                                        onChange={(nv: any) => {
                                            this.handleOnChange(
                                                nv,
                                                'Description'
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full  my-4 flex">
                                <div className="w-full lg:w-2/12 px-4 mt-6">
                                    <ImageUploaderInput
                                        label={'product image'}
                                        value={product?.ImageUrl ?? ''}
                                        onChange={(ev) =>
                                            this.handleOnChange(ev, 'ImageUrl')
                                        }
                                    />
                                </div>
                                <div className="w-full lg:w-10/12 px-4 mt-6">
                                    <LocalizedRichTextInput
                                        label={'contenido'}
                                        value={product?.Features ?? {}}
                                        onChange={(ev: any) =>
                                            this.handleOnChange(ev, 'Features')
                                        }
                                    />
                                </div>
                            </div>
                        </Section>
                        <Section
                            title={`Prices`}
                            collapsable={false}
                            defaultCollapsed={false}
                        >
                            <div className="px-4">
                            {product?.Prices?.filter((p: any) => p.Active).map(
                                (p: any) => {
                                    return (
                                        <Price
                                            t={t}
                                            price={p}
                                            onChange={this.handleOnPriceChange}
                                        />
                                    );
                                }
                            )}
                            </div>
                            {/*<Button*/}
                            {/*    className={'ml-6 mt-8 text-green-600'}*/}
                            {/*    text={'Add new price'}*/}
                            {/*    onClick={this.handleAddNewPrice}*/}
                            {/*/>*/}
                        </Section>
                    </div>
                </form>
            </PageWrapper>
        );
    }
}

export const EditProductPage = withRouter(_EditProductPage);

const Price = ({ t, price, onChange }: any) => {
    return (
        <Section
            title={price.Name}
            collapsable={false}
            defaultCollapsed={false}
        >
            <div className={`w-full flex my-4 items-center`}>
                {/*<div className="w-full lg:w-6/12 px-4">*/}
                {/*    <label*/}
                {/*        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"*/}
                {/*        htmlFor="grid-name"*/}
                {/*    >*/}
                {/*        {t('name')}*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        id="grid-name"*/}
                {/*        type="text"*/}
                {/*        value={price.Name}*/}
                {/*        onChange={(e) => {*/}
                {/*            onChange('Name', price.ID, e.currentTarget.value);*/}
                {/*        }}*/}
                {/*        className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}*/}
                {/*    />*/}
                {/*</div>*/}
            {/*    <div className="w-full lg:w-2/12 px-4">*/}
            {/*        <label*/}
            {/*            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"*/}
            {/*            htmlFor="grid-name"*/}
            {/*        >*/}
            {/*            {t('type')}*/}
            {/*        </label>*/}
            {/*        <input*/}
            {/*            disabled={true}*/}
            {/*            id="grid-name"*/}
            {/*            value={'Periodic'}*/}
            {/*            type="text"*/}
            {/*            className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="w-full lg:w-2/12 px-4">*/}
            {/*        <label*/}
            {/*            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"*/}
            {/*            htmlFor="grid-name"*/}
            {/*        >*/}
            {/*            {t('interval')}*/}
            {/*        </label>*/}
            {/*        <Select*/}
            {/*            classNamePrefix="gk-tenant-select"*/}
            {/*            options={IntervalOptions}*/}
            {/*            isSearchable={false}*/}
            {/*            isDisabled={price.StripeID !== ''}*/}
            {/*            onChange={(e) => {*/}
            {/*                onChange('Interval', price.ID, e?.value ?? '');*/}
            {/*            }}*/}
            {/*            value={{*/}
            {/*                label: price.Interval.toUpperCase(),*/}
            {/*                value: price.Interval,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            </div>
            <div className="w-full flex my-4">
                <div className="w-full lg:w-2/12 px-4">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                        htmlFor="grid-name"
                    >
                        {t('Amount')} (x100)
                    </label>
                    <input
                        id="grid-name"
                        type="currency"
                        value={price.Amount}
                        // disabled={price.StripeID !== ''}
                        onChange={(e) => {
                            const pr =  parseInt(e.currentTarget.value, 10)
                            onChange(
                                'Amount',
                                price.ID,
                                pr
                            );
                        }}
                        className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear ${price.Amount<price.Fee?'border-red-600 ring-red-300':''}`}
                    />
                    <p className="italic text-xs mt-1">Precio mínimo: {price.Fee}</p>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                        htmlFor="grid-name"
                    >
                        {t('currency')}
                    </label>
                    <Select
                        classNamePrefix="gk-tenant-select"
                        options={CurrencyOptions}
                        isSearchable={false}
                        isDisabled={true}
                        onChange={(e) => {
                            console.log(e);
                            onChange('Interval', price.ID, e?.value ?? '');
                        }}
                        value={{
                            label: price.Currency.toUpperCase(),
                            value: price.Currency,
                        }}
                    />
                </div>
            </div>
            {/*<div className="w-full px-4 mb-4 flex justify-end">*/}
            {/*    <Button*/}
            {/*        style="danger"*/}
            {/*        text="Delete price"*/}
            {/*        onClick={(ev) => onChange('Active', price.ID, false)}*/}
            {/*    />*/}
            {/*</div>*/}
        </Section>
    );
};

const IntervalOptions = [
    {
        label: 'Year',
        value: 'year',
    },
    {
        label: 'Month',
        value: 'month',
    },
];

const CurrencyOptions = [
    {
        label: 'Eur',
        value: 'eur',
    },
];
