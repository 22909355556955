import React, {useEffect, useState} from "react";
import {JsonInput} from "../../forms/inputs/JsonInput";
import { CssInput } from "../../forms/inputs/CssInput";

export const RSEFCss = ({field, onChange, resource}:any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if(resource) {
            const aux = resource.Get(field.field)
            const value = aux
            setValue(value)
        }
    }, [resource])

    const handleOnChange = (nv:any) => {
        onChange(nv, field.field)
    }

    return <CssInput label={field.name || field.field}  value={value} onChange={handleOnChange}/>
}
