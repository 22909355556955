import React, {useEffect, useState} from "react";
import {JsonInput} from "../../forms/inputs/JsonInput";

export const RSEFJson = ({field, onChange, resource}:any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if(resource) {
            const aux = resource.Get(field.field)
            let value = undefined
            try {
                value = JSON.parse(aux)
                setValue(value)
            } catch(e) {

            }
        }
    }, [resource])

    const handleOnChange = (nv:any) => {
        try {
            const aux = JSON.parse(nv)
            onChange(nv, field.field)
        } catch(err) {}
    }

    return <JsonInput label={field.name || field.field}  value={value} onChange={handleOnChange}/>
}
