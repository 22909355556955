import React, {ChangeEvent, ChangeEventHandler, useState} from 'react';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale    from 'react-json-editor-ajrm/locale/en';

export interface JsonInputProps {
    label?: string;
    value?: object;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const JsonInput = ({
    label = '',
    value = {},
    onChange,
}: JsonInputProps) => {
    const handleOnChange = (changes: any) => {
        onChange && onChange(changes.json);
    };

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor={`grid-json-${label}`}
                >
                    {label}
                </label>
            )}
            <JSONInput
                id          = {`grid-json-${label}`}
                placeholder = { value }
                theme="light_mitsuketa_tribute"
                locale      = { locale }
                height      = '550px'
                style={{
                    container: {
                        border: '1px solid rgb(55, 65, 81)',
                        borderRadius: 4,
                        boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                        width: '100%'
                    },
                    outerBox: {
                        width: '100%'
                    }
                }}
                onChange = {handleOnChange}
                onBlur = {handleOnChange}
            />
        </>
    );
};


