import { PageWrapper } from "../../PageWrapper";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { Section } from "../../../components/sections/Section";
import { Button } from "../../../components/buttons/Button";
import { TextInput } from "../../../components/forms/inputs/TextInput";
import Select from "react-select";
import TenantContext from "../../../TenantContext";
import { RenderPropSticky } from "react-sticky-el";
import { GetResourceFromName } from "../../../models/ResourceFactory";
import { Account, TAccount } from "../../../models/Accounts";
import { ImageUploaderInput } from "../../../components/forms/inputs/ImageUploaderInput";

type EditAccountState = {
  account: TAccount;
  loading: boolean;
  deleting: boolean;
  currentId: number;
};

interface EditAccountProps extends RouteComponentProps {
}

class _EditAccountPage extends React.Component<
  EditAccountProps,
  EditAccountState
> {
  static contextType = TenantContext;

  constructor(props: EditAccountProps) {
    super(props);

    let id = (props.match.params as any).id;
    if (id === "new") {
      id = 0;
    } else {
      id = parseInt(id, 10);
    }

    this.state = {
      loading: true,
      deleting: false,
      account: null,
      currentId: id
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
  }

  async componentDidMount() {
    const tenant: any = _EditAccountPage.contextType;
    const np = new Account(tenant);
    const { currentId } = this.state;

    if (currentId === 0) {
      this.setState({ account: np });
    } else {
      await this.loadData();
    }

    this.setState({ loading: false });
  }

  async componentDidUpdate(
    prevProps: EditAccountProps,
    prevState: EditAccountState
  ) {
    const { currentId } = this.state;
    if (currentId !== prevState.currentId) {
      await this.loadData();
    }
  }

  async loadData() {
    this.setState({ loading: true });
    const { currentId } = this.state;
    // @ts-ignore
    const np = new GetResourceFromName("accounts", this.context);

    np.Retrieve(currentId as number).then(() => {
      this.setState({ account: np, loading: false });
    });
  }

  handleOnSave = async () => {
    const { account } = this.state;
    this.setState({ loading: true });

    const response = await account?.Save();
    this.setState({
      loading: false,
      currentId: response.ID,
      account: { ...response }
    });
    this.props.history.replace("/accounts/" + response.ID);
    NotificationManager.success("Save successfully");
  };

  handleOnDelete = async () => {
    const { account } = this.state;
    this.setState({ deleting: true });

    const response = await account?.Delete();
    this.setState({ deleting: false });
    this.props.history.replace("/accounts");
    NotificationManager.success("Save successfully");
  };

  handleOnChange = (event: any, field: string) => {
    const { account } = this.state;
    // @ts-ignore
    const np = new GetResourceFromName("account", this.context, account);
    const value =
      field !== "Active"
        ? event?.currentTarget?.value ?? event
        : event?.currentTarget.checked;

    np.Set(field, value);
    this.setState({ account: np });
  };

  render() {
    const { account, loading, deleting, currentId } = this.state;
    const id = currentId;
    const t = (t: string) => t;

    if (account) {
      debugger
    }

    if (!account) {
      return <div>Loading...</div>;
    }

    return (
      <PageWrapper title="">
        <RenderPropSticky
          scrollElement=".gk-main-wrapper"
          topOffset={0}
          onFixedToggle={() => {
            console.log("######## TOGGLE ");
          }}
        >
          {({
              isFixed,
              wrapperStyles,
              wrapperRef,
              holderStyles,
              holderRef
            }: any) => (
            <div ref={holderRef} style={holderStyles}>
              <div
                ref={wrapperRef}
                style={{
                  ...wrapperStyles,
                  left: 226,
                  width: "calc(100%)"
                }}
                className={`w-full px-4  flex justify-between bg-white z-10 h-40${
                  isFixed ? "border py-4 shadow-xl" : "my-4"
                }`}
              >
                <div className={"font-bold uppercase"}>
                  <Link to={`/accounts`}>
                                        <span className="underline cursor-pointer text-gray-600 hover:opacity-60">
                                            accounts
                                        </span>
                  </Link>
                  <span>{"  >  "}</span>
                  {currentId > 0
                    ? `account #${currentId}`
                    : `New account`}
                </div>
                <div className={"flex"}>
                  <Button
                    className={"mr-4"}
                    style={"danger"}
                    text={"Delete"}
                    disabled={loading || currentId === 0}
                    onClick={this.handleOnDelete}
                    loading={deleting}
                  />
                  <Button
                    style={"primary"}
                    text={"Save"}
                    disabled={deleting}
                    onClick={this.handleOnSave}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          )}
        </RenderPropSticky>
        <form>
          <div className={`${loading ? "opacity-30" : ""}`}>
            <Section
              title={"General"}
              collapsable={false}
              defaultCollapsed={false}
            >
              <div className="w-full px-4 my-4 flex items-center">
                <div className="w-full lg:w-2/12 px-4 mt-6">
                  <ImageUploaderInput
                    label={"account image"}
                    value={account?.ImageUrl ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "ImageUrl")
                    }
                  />
                </div>

                <div className="w-full lg:w-10/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"account Email"}
                    value={account?.Email ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "Email")
                    }
                  />
                </div>
                <div className="w-full lg:w-2/12 px-4 mt-6">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                  >
                    {t("Active")}
                  </label>
                  <input
                    className={
                      "cursor-pointer form-checkbox mr-4"
                    }
                    type="checkbox"
                    id="cbox2"
                    checked={account?.Active}
                    style={{ width: 18, height: 18 }}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "Active")
                    }
                  />
                </div>
                <div className="w-full lg:w-2/12 px-4 mt-6">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                  >
                    {t("Verified")}
                  </label>
                  <input
                    className={
                      "cursor-pointer form-checkbox mr-4"
                    }
                    type="checkbox"
                    id="cbox2"
                    checked={account?.VerifiedAt}
                    style={{ width: 18, height: 18 }}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "Verified")
                    }
                  />
                </div>
                <div className="w-full lg:w-2/12 px-4 mt-6">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                  >
                    {t("Banned")}
                  </label>
                  <input
                    className={
                      "cursor-pointer form-checkbox mr-4"
                    }
                    type="checkbox"
                    id="cbox2"
                    checked={account?.Banned}
                    style={{ width: 18, height: 18 }}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "Banned")
                    }
                  />
                </div>
              </div>
              <div className="w-full px-4 my-4 flex items-center">
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"First Name"}
                    value={account?.FirstName ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "FirstName")
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"Last Name"}
                    value={account?.LastName ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "LastName")
                    }
                  />
                </div>
              </div>

            </Section>
            <Section
              title={"Billing Details"}
              collapsable={true}
              defaultCollapsed={true}
            >
              <div className="w-full px-4 my-4 flex items-center">
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"Name"}
                    value={(account as any)?.billing_data_name ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataName")
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"email"}
                    value={(account as any)?.billing_data_email ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataEmail")
                    }
                  />
                </div>
              </div>
              <div className="w-full px-4 my-4 flex items-center">
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"Phone"}
                    value={(account as any)?.billing_data_phone ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataPhone")
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"VAT"}
                    value={(account as any)?.billing_data_vat ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataVat")
                    }
                  />
                </div>
              </div>
              <div className="w-full px-4 my-4 flex items-center">
              <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"address"}
                    value={(account as any)?.billing_data_address ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataAddress")
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"city"}
                    value={(account as any)?.billing_data_city ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataCity")
                    }
                  />
                </div>
              </div>
              <div className="w-full px-4 my-4 flex items-center">

              <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"state"}
                    value={(account as any)?.billing_data_state ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataState")
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"country"}
                    value={(account as any)?.billing_data_country ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataCountry")
                    }
                  />
                </div>
              </div>
              <div className="w-full px-4 my-4 flex items-center">

              <div className="w-full lg:w-6/12 px-4 mt-6">
                  <TextInput
                    type={"text"}
                    label={"zip code"}
                    value={(account as any)?.billing_data_zip_code ?? ""}
                    onChange={(ev) =>
                      this.handleOnChange(ev, "BillingDataZipCode")
                    }
                  />
                </div>
              </div>
            </Section>
            <Section
              title={"Account Details"}
              collapsable={false}
              defaultCollapsed={false}
            >
              {account &&
                <div className="w-full px-4 my-4 items-center">
                  <div>
                    <span>FusionAuthId: </span>
                    <span>{(account as any)?.fusion_auth_id}</span>
                  </div>
                  <div>
                    <span>ProviderType: </span>
                    <span>{(account as any)?.ProviderType}</span>
                  </div>
                  <div>
                    <span>ProviderId: </span>
                    <span>{(account as any)?.provider_id}</span>
                  </div>
                  <div>
                    <span>StripeId: </span>
                    <span>{(account as any)?.StripeID}</span>
                  </div>
                  <div>
                    <span>Language: </span>
                    <span>{(account as any)?.Language}</span>
                  </div>
                  <div>
                    <span>Country: </span>
                    <span>{(account as any)?.Country}</span>
                  </div>
                  <div>
                    <span>Currency: </span>
                    <span>{(account as any)?.DefaultCurrency}</span>
                  </div>
                  <div>
                    <span>Roles: </span>
                    <span>[{(account as any)?.Roles?.join(",")}]</span>
                  </div>

                </div>
              }
            </Section>

          </div>
        </form>
      </PageWrapper>
    );
  }
}

export const EditAccountPage = withRouter(_EditAccountPage);

const Price = ({ t, price, onChange }: any) => {
  return (
    <Section
      title={price.Name}
      collapsable={false}
      defaultCollapsed={false}
    >
      <div className={`w-full flex my-4 items-center`}>

      </div>
      <div className="w-full flex my-4">
        <div className="w-full lg:w-2/12 px-4">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
            htmlFor="grid-name"
          >
            {t("Amount")} (x100)
          </label>
          <input
            id="grid-name"
            type="currency"
            value={price.Amount}
            // disabled={price.StripeID !== ''}
            onChange={(e) => {
              const pr = parseInt(e.currentTarget.value, 10);
              onChange(
                "Amount",
                price.ID,
                pr
              );
            }}
            className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear ${price.Amount < price.Fee ? "border-red-600 ring-red-300" : ""}`}
          />
          <p className="italic text-xs mt-1">Precio mínimo: {price.Fee}</p>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
            htmlFor="grid-name"
          >
            {t("currency")}
          </label>
          <Select
            classNamePrefix="gk-tenant-select"
            options={CurrencyOptions}
            isSearchable={false}
            isDisabled={true}
            onChange={(e) => {
              console.log(e);
              onChange("Interval", price.ID, e?.value ?? "");
            }}
            value={{
              label: price.Currency.toUpperCase(),
              value: price.Currency
            }}
          />
        </div>
      </div>
    </Section>
  );
};

const IntervalOptions = [
  {
    label: "Year",
    value: "year"
  },
  {
    label: "Month",
    value: "month"
  }
];

const CurrencyOptions = [
  {
    label: "Eur",
    value: "eur"
  }
];
