
import { Resource } from './Resource';
import {ResourceSettings} from "./ResourceSettings";

export class Theme extends Resource {
    Name: string = '';
    Style: string = '';
    Content: string = '';
    ContentObject: any;
    Active: boolean = false;

    constructor(tenantId: string, theme?: any) {
        super('themes', tenantId, theme?.ID ?? 0);
        this.SetBody(theme);
    }

    async Retrieve(id: number): Promise<void> {
        await super.Retrieve(id);
        this.ContentObject = JSON.parse(this.Content)
    }

    Set(field: string, value: string | number) {
        // @ts-ignore
        this[field] = value;
    }

    GetBody() {
        const body: any = super.GetBody();
        delete body.ContentObject;
        return body;
    }

    GetNames(): string[] {
        return ["theme", "themes"]
    }

    GetResourceSettings(): ResourceSettings {
        return  {
            checks: true,
            resource: {
                name: 'themes',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                { text: 'Name', field: 'Name', sortable: true, searchable: true },
                { text: 'Style', field: 'Style', sortable: false, searchable: true},
                { text: 'Active', field: 'Active', sortable: false, searchable: true, type:'check'},
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return (resource?.Get("Name") !== "") && (resource?.Get("Style") !== "");
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields:[
                            { field: 'Logo', type: 'image', width: 2},
                            { field: 'LogoMini', type: 'image', width: 2},
                            { field: 'NoDataImage', type: 'image', width: 2},
                            { name: "Theme name", field:"Name", type: 'input', width: 2, data: 'text' },
                            { field: "Style", type: 'input', width: 2, data: 'text' },
                            { field:"Active", type: 'checkbox', width: 2 },
                            { field:"Content", type: 'json', width: 12 }
                        ]
                    }
                ]
            }
        };
    }
}
