import React from 'react';
import { Section } from '../sections/Section';
import { ResourceEditorField } from './ResourceEditorField';

export const ResourceEditorSection = ({ section, onChange, resource }: any) => {
    return (
        <Section
            title={section.title}
            collapsable={section.collapsable}
            defaultCollapsed={section.collapsed}
        >
            <div className="w-full px-4 my-4 flex flex-wrap items-start">
                {section.fields.map((f: any) => (
                    <div className={`w-full lg:w-${f.width}/12 px-4 mt-6`}>
                        <ResourceEditorField
                            field={f}
                            onChange={onChange}
                            resource={resource}
                        />
                    </div>
                ))}
            </div>
        </Section>
    );
};
