import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import {GKBilling} from "gkshared";

const gkb = new GKBilling(process.env.REACT_APP_APPLICATION_ID || '', "/", true);

export default function LoginComponent({ setToken, setAuthInfo }: any) {
    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const {token} = await gkb.Login(username, password, 'credentials');
        if (token) {
            setToken(token.access_token);
            const decoded:any = jwtDecode(token.access_token);
            if(decoded) {
                delete decoded.aud;
                delete decoded.exp;
                delete decoded.iat;
                delete decoded.iss;
                delete decoded.sub;
                delete decoded.jti;
                delete decoded.email_verified;
                delete decoded.applicationId;
                setAuthInfo({...decoded, applications: token.applications, account: token.account});
            }
        }
    };

    return (
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden" style={{ backgroundColor: '#365314'}}>
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
              <h1 className="text-3xl font-semibold text-center">
                  KardSystem Dashboard
              </h1>
              <form className="mt-6" onSubmit={handleSubmit}>
                  <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold text-gray-800"
                      >
                          Email
                      </label>
                      <input
                        defaultValue={username}
                        onChange={(e) => setUserName(e.target.value)}
                        type="email"
                        className="block w-full px-4 py-2 mt-2 text-green-700 bg-white border rounded-md focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                  </div>
                  <div className="mb-2">
                      <label
                        htmlFor="password"
                        className="block text-sm font-semibold text-gray-800"
                      >
                          Password
                      </label>
                      <input
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className="block w-full px-4 py-2 mt-2 text-green-700 bg-white border rounded-md focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                  </div>
                  <a
                    href="#"
                    className="text-xs text-green-600 hover:underline"
                  >
                      Forget Password?
                  </a>
                  <div className="mt-6">
                      <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-700 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">
                          Login
                      </button>
                  </div>
              </form>

          </div>
      </div>

    );
}
