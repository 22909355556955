import React, { ChangeEventHandler } from 'react';
import { ImageUploader } from '../uploaders/ImageUploader';

export interface ImageUploaderInputProps {
    label?: string;
    resource?: string;
    value?: string;
    onChange?: (value: string) => void;
}

export const ImageUploaderInput = ({
    label = '',
    value = '',
    onChange,
}: ImageUploaderInputProps) => {
    const handleOnChange = (value: string) => {
        onChange && onChange(value);
    };
    return (
        <>
            {label !== '' && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                >
                    {label}
                </label>
            )}
            <ImageUploader value={value} onChange={handleOnChange} />
        </>
    );
};
