import React, {useEffect, useState} from "react";
import { LocalizedTextArea } from "../../forms/inputs/LocalizedTextArea";

export const RSEFLocalizedTextArea = ({field, onChange, resource}:any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if(resource) {
            const aux = resource.Get(field.field)
            setValue(aux)
        }
    }, [resource])

    const handleOnChange = (nv:any) => {
        onChange(nv, field.field)
    }

    return <LocalizedTextArea label={field.name || field.field} value={value} onChange={handleOnChange}/>
}
