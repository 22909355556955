import React from 'react';
import { PageWrapper } from './PageWrapper';

export interface GenericPageProps {
}

export const SubscriptionDetailsPage = ({}: GenericPageProps) => {
    return (
        <PageWrapper title={'title'} >
            <p>Aqui va el contenido de la página...</p>
        </PageWrapper>
    );
};
