import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './main';

// const base = document.location.pathname.split('/')[1];
// document.head.innerHTML =
//     document.head.innerHTML +
//     "<base href='" +
//     `${document.location.origin}/${base}` +
//     "' />";

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        return null;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
