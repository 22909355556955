import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { GKUploader } from 'gkshared';
import './RichTextEditor.css';

const gku = new GKUploader(process.env.REACT_APP_APPLICATION_ID || '');

export interface RichTextEditorProps {
    onChange: any;
    value: string;
}

function imageUploader(blobInfo, success, failure) {
    gku.UploadBlob(blobInfo.blobUri())
        .then((res) => {
            success(res);
        })
        .catch((err) => {
            failure(err);
        });
}

export const RichTextEditor = ({
    value = '',
    onChange,
}: RichTextEditorProps) => {
    const editorRef: any = useRef(null);

    const handleOnChange = (e: any) => {
        onChange &&
            editorRef?.current &&
            onChange(editorRef.current.getContent());
    };

    return (
        <div>
            <Editor
                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/mytinymce@1.0.0/tinymce.min.js"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={value}
                onEditorChange={handleOnChange}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help',
                    ],
                    toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | image | code',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    file_picker_types: 'image',
                    images_upload_url: '/gku/api/uploads',
                    images_reuse_filename: true,
                    images_upload_credentials: true,
                    images_upload_handler: imageUploader,
                    file_picker_callback: function (cb, value, meta) {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();
                            reader.onload = function () {
                                const id = 'blobid' + new Date().getTime();
                                const blobCache =
                                    editorRef?.current.editorUpload.blobCache;
                                const result: any = reader.result;
                                const base64 = result.split(',')[1];
                                const blobInfo = blobCache.create(
                                    id,
                                    file,
                                    base64
                                );
                                blobCache.add(blobInfo);

                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                        };
                        input.click();
                    },
                }}
            />
        </div>
    );
};
