import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../assets/output.css';
import '../assets/fontawesome/css/all.css';
import './App.css';
import Dashboard from './components/Dashboard';
import Preferences from './components/Preferences';
import LoginComponent from './components/login/login';
import { GKBilling } from 'gkshared';
import { SidenavComponent } from './components/sidenav/Sidenav';
import { HeaderComponent } from './components/header/Header';
import { EditProductPage } from './pages/ECommerce/products/EditProduct';
import { AuthContextProvider } from './AuthContext';
import BgLoader from './components/bgLoader/BgLoader';
import { TenantContextProvider } from './TenantContext';
import { ResourceList } from './components/ResourceList';
import { ResourceEditPage } from './components/ResourceEditPage';
import { LocalizedTextEditResourcePage } from './components/LocalizedStringResourceEditPage';
import { EditTutorialPage } from './pages/CMS/tutorials/EditTutorial';
import { EditAccountPage } from './pages/ECommerce/accounts/EditAccount';
import { SubscriptionDetailsPage } from './pages/SubscriptionDetails';

const gkb = new GKBilling(process.env.REACT_APP_APPLICATION_ID || '',  "/", true);

function App() {
    const [token, setToken] = useState<string>('');
    const [authInfo, setAuthInfo] = useState<any>('');
    const [tenant, setTenant] = useState<string>('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const relogin = async () => {
            const token = await gkb.RefreshToken();
            if (token) {
                setToken(token.access_token);
                const decoded: any = jwt_decode(token.access_token);
                delete decoded.aud;
                delete decoded.exp;
                delete decoded.iat;
                delete decoded.iss;
                delete decoded.sub;
                delete decoded.jti;
                delete decoded.email_verified;
                delete decoded.applicationId;
                setAuthInfo({
                    ...decoded,
                    applications: token.applications,
                    account: token.account,
                });
            }
        };

        relogin().then((r) => {
            setLoading(false);
        });

        const t = localStorage.getItem('lastTenantId');
        if (t && t !== 'undefined') {
            const tenant = JSON.parse(t);
            setTenant(tenant.value.application_id);
        }
    }, []);

    if (loading) {
        return <BgLoader />;
    }

    if (!token) {
        return <LoginComponent setToken={setToken} setAuthInfo={setAuthInfo} />;
    }

    const handleTenantChange = (tenant: any) => {
        const ai = { ...authInfo };
        ai.tenant = tenant?.value?.id || 0;
        setAuthInfo(ai);
        setTenant(tenant?.value?.application_id || '');
    };

    return (
        <AuthContextProvider value={authInfo}>
            <TenantContextProvider value={tenant}>
                <NotificationContainer />
                <div>
                    <BrowserRouter basename={'/gkbo'}>
                        <HeaderComponent onTenantChange={handleTenantChange} />
                        <div className="flex flex-row min-h-screen bg-gray-100 text-gray-800 mt-20">
                            <SidenavComponent />
                            <div className="gk-main-wrapper overflow-y-auto w-full h-screen">
                                <main className="main-content flex flex-col flex-grow">
                                    <Switch>
                                        <Route exact path="/">
                                            <Redirect to="/dashboard/general" />
                                        </Route>
                                        <Route path="/dashboard/general">
                                            <Dashboard id={4} title={"General"}/>
                                        </Route>
                                        <Route exact path="/products/:id">
                                            <EditProductPage />
                                        </Route>
                                        <Route exact path={`/products`}>
                                            <ResourceList
                                                resource={'products'}
                                            />
                                        </Route>
                                        <Route exact path="/tutorials/:id">
                                            <EditTutorialPage />
                                        </Route>
                                        <Route exact path={`/tutorials`}>
                                            <ResourceList
                                                resource={'tutorials'}
                                            />
                                        </Route>
                                        <Route path="/preferences">
                                            <Preferences />
                                        </Route>

                                        <Route exact path="/themes/:id">
                                            <ResourceEditPage
                                                resource={'theme'}
                                            />
                                        </Route>
                                        <Route path="/themes">
                                            <ResourceList resource={'themes'} />
                                        </Route>
                                        <Route exact path="/texts/:id">
                                            <LocalizedTextEditResourcePage
                                                resource={'texts'}
                                            />
                                        </Route>
                                        <Route path="/texts">
                                            <ResourceList resource={'texts'} />
                                        </Route>
                                        <Route exact path="/faqs/:id">
                                            <ResourceEditPage
                                                resource={'faq'}
                                            />
                                        </Route>
                                        <Route path="/faqs">
                                            <ResourceList resource={'faqs'} />
                                        </Route>
                                        <Route exact path="/legals/:id">
                                            <ResourceEditPage
                                                resource={'legal'}
                                            />
                                        </Route>
                                        <Route path="/legals">
                                            <ResourceList resource={'legals'} />
                                        </Route>
                                        <Route exact path="/slides/:id">
                                            <ResourceEditPage
                                                resource={'slides'}
                                            />
                                        </Route>
                                        <Route path="/slides">
                                            <ResourceList resource={'slides'} />
                                        </Route>
                                        <Route exact path="/sections/:id">
                                            <ResourceEditPage
                                                resource={'sections'}
                                            />
                                        </Route>
                                        <Route path="/sections">
                                            <ResourceList
                                                resource={'sections'}
                                            />
                                        </Route>
                                        <Route exact path="/templates/:id">
                                            <ResourceEditPage
                                              resource={'templates'}
                                            />
                                        </Route>
                                        <Route path="/templates">
                                            <ResourceList
                                              resource={'templates'}
                                            />
                                        </Route>
                                        <Route exact path="/features/:id">
                                            <ResourceEditPage
                                                resource={'features'}
                                            />
                                        </Route>
                                        <Route path="/features">
                                            <ResourceList
                                                resource={'features'}
                                            />
                                        </Route>
                                        <Route path="/subscriptions/:id">
                                            <SubscriptionDetailsPage/>
                                        </Route>
                                        <Route path="/subscriptions">
                                            <ResourceList
                                                resource={'subscriptions'}
                                            />
                                        </Route>
                                        <Route exact path="/accounts/:id">
                                            <EditAccountPage/>
                                        </Route>
                                        <Route path="/accounts">
                                            <ResourceList
                                              resource={'accounts'}
                                            />
                                        </Route>
                                    </Switch>
                                </main>
                            </div>
                        </div>
                    </BrowserRouter>
                </div>
            </TenantContextProvider>
        </AuthContextProvider>
    );
}

export default App;
