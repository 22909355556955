
import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import {ResourceEditor, ResourceSettings} from "./ResourceSettings";

export type TLegal = Legal | null;

export class Legal extends Resource {
    Name: string = '';
    Title?: LocalizedString;
    Content?: LocalizedString;

    constructor(tenantId: string, faq?: any) {
        super('legals', tenantId, faq?.ID ?? 0);
        this.SetBody(faq);
    }

    GetNames(): string[] {
        return ["legal", "legals"]
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'legals',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                { text: 'Name', field: 'Name', sortable: true, searchable: true },
                { text: 'Title', field: 'Title', sortable: false, searchable: true, type: 'localizedString'},
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return (resource?.Get("Name") !== "");
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields:[
                            { name: "Legal name", field:"Name", type: 'input', width: 6, data: 'text' },
                            { field: "Title", type: 'localizedInput', width: 6 },
                            { field:"Content", type: 'localizedRichText', width: 12 }
                        ]
                    }
                ]
            }
        }
    }
}
