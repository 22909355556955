
import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import {ResourceSettings} from "./ResourceSettings";

export type TFaq = Faq | null;

export class Faq extends Resource {
    Name: string = '';
    Category: string = '';
    Active: boolean = false;
    Question?: LocalizedString;
    Response?: LocalizedString;

    constructor(tenantId: string, faq?: any) {
        super('faqs', tenantId, faq?.ID ?? 0);
        this.SetBody(faq);
    }

    GetNames(): string[] {
        return ["faq", "faqs"]
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'faqs',
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                { text: 'Category', field: 'Category', sortable: true, searchable: true },
                { text: 'Question', field: 'Question', sortable: false, searchable: true, type: 'localizedString'},
                { text: 'Response', field: 'Response', sortable: false, searchable: true, type: 'localizedString'},
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor:{
                isValid: (resource: Resource | null)=> {
                    return resource?.Get("Category") !== ""
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            { field: 'Category', type: 'select', width: 2, data: categories},
                            { field: 'Active', type: 'checkbox', width: 2},
                            { field: '', type: 'separator', width: 8},
                            { field:"Question", type: 'localizedInput', width: 4 },
                            { field:"Response", type: 'localizedTextArea', width: 8 },
                        ]
                    }
                ]
            }
        }
    }
}

const categories = [
    {
        label: 'Cards',
        value: 'cards',
    },
    {
        label: 'Resellers',
        value: 'resellers',
    },
    {
        label: 'Billing',
        value: 'billing',
    },
]