import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from '../models/ResourceSettings';
import { ResourceEditorComponent } from './ResourceEditor/ResourceEditor';

type EditResourceState = {
    currentId: number;
};

interface EditResourceProps extends RouteComponentProps {
    resource: string;
}

class _EditResourcePage extends React.Component<
    EditResourceProps,
    EditResourceState
> {
    constructor(props: EditResourceProps) {
        super(props);
        let id = (props.match.params as any).id;
        if (id === 'new') {
            id = 0;
        } else {
            id = parseInt(id, 10);
        }
        this.state = {
            currentId: id,
        };
    }

    render() {
        const { currentId } = this.state;
        const { resource } = this.props;
        return <ResourceEditorComponent resource={resource} id={currentId} />;
    }
}

export const ResourceEditPage = withRouter(_EditResourcePage);
