import React, {useEffect, useState} from 'react';
import { PageWrapper } from '../pages/PageWrapper';
import {GKBilling} from "gkshared";
const gkb = new GKBilling(process.env.REACT_APP_APPLICATION_ID || '', "/", true);

export default function Dashboard({ tenant, id, title }: any) {
  const [iframeUrl, setIframeUrl] =  useState('')
  useEffect(() => {
    gkb.GetDashboardUrl(id).then((response) => {
      setIframeUrl(response.data.data)
      console.log(response.data.data)
    })
  }, [])

    return (
        <PageWrapper title={title}>
            <h2>{tenant}</h2>
          {iframeUrl &&
          <iframe
            src={iframeUrl}
            frameBorder={0}
            width={'100%'}
            height={'100%'}
            allowTransparency
          />}
        </PageWrapper>
    );
}
