import { GKBilling, GKUploader } from 'gkshared';
import dotProp from 'dot-prop';
import { ResourceSettings } from './ResourceSettings';

export abstract class Resource {
    ResourceName: string;
    ID: number;
    protected gkb: any;
    private tenantId: string;

    constructor(resource: string, tenantId: string, body: any) {
        this.ResourceName = resource;
        this.ID = body?.ID ?? 0;
        this.SetBody(body || {});
        this.tenantId = tenantId || '';
        this.gkb = new GKBilling(
            tenantId || process.env.REACT_APP_APPLICATION_ID || '', '/', true
        );
    }

    IsNew() {
        return this.ID === 0;
    }

    Set(field: string, value: string | number) {
        const fld = dotProp.get(this, field);
        if (typeof fld === 'number' && typeof value === 'string') {
            const aux = parseInt(value, 10);
            if (isNaN(aux)) {
                value = 0;
            } else {
                value = aux;
            }
        }
        dotProp.set(this, field, value);
    }

    Get(field: string): any {
        return dotProp.get(this, field);
    }

    GetBody() {
        const body: any = {};
        for (const [key, value] of Object.entries(this)) {
            // @ts-ignore
            body[key] = value;
        }

        delete body.gkb;
        delete body.tenantId;
        return body;
    }

    SetBody(body: any = {}) {
        for (const [key, value] of Object.entries(body)) {
            if (key === 'gbk') {
                continue;
            }

            // @ts-ignore
            this[key] = value;
        }
    }

    async Save() {
        let resp;
        await this.uploadImages();
        if (this.ID === 0) {
            resp = await this.gkb.CreateApiResource({
                name: this.ResourceName,
                body: this.GetBody(),
            });
            this.ID = resp.ID;
        } else {
            const body = this.GetBody();
            resp = await this.gkb.UpdateApiResource({
                name: this.ResourceName,
                id: this.ID,
                body: body,
            });
        }
        return resp;
    }

    async Retrieve(id: number): Promise<void> {
        const res = await this.gkb.GetApiResource(this.ResourceName, id);
        if (res) {
            this.SetBody(res);
        }
    }

    async Delete(): Promise<void> {
        const res = await this.gkb.DeleteApiResource(
            this.ResourceName,
            this.ID
        );
        if (res) {
            this.SetBody(res);
        }
    }

    async uploadImages(): Promise<void> {
        const gku = new GKUploader(
            this.tenantId || process.env.REACT_APP_APPLICATION_ID || ''
        );

        for (const [key, value] of Object.entries(this)) {
            if (typeof value === 'string' && value.startsWith('blob:')) {
                const res = await gku.UploadBlob(
                    value,
                    undefined,
                    this.ResourceName
                );
                if (res) {
                    this[key] = res;
                }
            }
        }
        return;
    }

    abstract GetNames(): string[];
    abstract GetResourceSettings(): ResourceSettings;
}
