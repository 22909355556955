import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import { ResourceSettings } from './ResourceSettings';
import { Tutorial } from './Tutorials';

export type TTemplate = Template | null;

export class Template extends Resource {
    static Names = ['template', 'templates'];
    Name: string = '';
    Subject: LocalizedString;
    HTMLContent: LocalizedString;
    TextContent: LocalizedString;
    Active: boolean;

    constructor(tenantId: string, feature?: any) {
        super(Template.Names[1], tenantId, feature?.ID ?? 0);
        this.Subject = new LocalizedString(tenantId);
        this.HTMLContent = new LocalizedString(tenantId);
        this.TextContent = new LocalizedString(tenantId);
        this.Active = true;

        this.SetBody(feature);
    }

    GetNames(): string[] {
        return Template.Names;
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: Template.Names[1],
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Name',
                    field: 'Name',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: 'Subject',
                    field: 'Subject',
                    type: 'localizedString',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: 'Active',
                    field: 'Active',
                    sortable: false,
                    searchable: false,
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return resource?.Get('Content') !== '';
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                field: 'Name',
                                type: 'input',
                                width: 4,
                                data: 'text',
                            },
                            {
                                field: 'Active',
                                type: 'checkbox',
                                width: 4,
                            },
                            {
                                field: 'Bcc',
                                type: 'input',
                                width: 4,
                                data: 'text',
                            },
                            {
                                field: 'Subject',
                                type: 'localizedInput',
                                width: 12,
                            },
                            {
                                field: 'HTMLContent',
                                type: 'localizedRichText',
                                width: 12,
                            },
                            {
                                field: 'TextContent',
                                type: 'localizedRichText',
                                width: 12,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
