import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { ChromePicker } from 'react-color';

export interface ColorInputProps {
    label?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const ColorInput = ({
    label = '',
    value = '',
    onChange,
}: ColorInputProps) => {
    const handleOnChange = (color) => {
        onChange && onChange(color.hex);
    };

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-name"
                >
                    {label}
                </label>
            )}
            <ChromePicker
                id="grid-name"
                color={value}
                onChange={handleOnChange}
                className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
            />
        </>
    );
};
