import React, { createRef, useContext, useEffect, useState } from 'react';
import { Table } from './table/Table';
import { useHistory } from 'react-router-dom';
import TenantContext from '../TenantContext';
import { PageWrapper } from '../pages/PageWrapper';
import { GetResourceFromName } from '../models/ResourceFactory';
import { ResourceSettings } from '../models/ResourceSettings';

export interface ThemesPageProps {
    resource: string;
}

export const ResourceList = ({ resource }: ThemesPageProps) => {
    const tableRef = createRef();
    const history = useHistory();
    const tenant = useContext(TenantContext);
    const [tableSettings, setTableSettings] = useState<ResourceSettings>();

    useEffect(() => {
        const Resource = GetResourceFromName(resource, tenant);
        if (Resource) {
            const table = Resource.GetResourceSettings();
            setTableSettings(table);
        }
    }, [resource]);

    const handleTableAction = async (action: any, rows: any) => {
        if (action === 'edit') {
            history.push(`/${resource}/${rows[0].ID}`);
            return;
        }

        if (action === 'new') {
            history.push(`/${resource}/new`);
            return;
        }

        if (action === 'delete') {
            const promises = [];
            for (let i = 0; i < rows.length; i++) {
                const res = GetResourceFromName(resource, tenant, rows[i]);
                promises.push(res?.Delete());
            }
            await Promise.all(promises);

            (tableRef.current as any).reload();
            return;
        }

        tableSettings?.customTableAction &&
            tableSettings.customTableAction(action, rows);
    };

    const handleRenderAction = (action: any, row: any) => {
        if (tableSettings?.customRenderAction) {
            return tableSettings?.customRenderAction(action, row);
        }

        return true;
    };

    const handleRenderText = (value: any, column: any, row: any) => {
        if (tableSettings?.customRenderText) {
            return tableSettings?.customRenderText(value, column, row);
        }

        return value;
    };

    return (
        <PageWrapper title={resource}>
            <Table
                tenant={tenant}
                ref={tableRef}
                settings={tableSettings}
                onAction={handleTableAction}
                renderAction={handleRenderAction}
                renderText={handleRenderText}
            />
        </PageWrapper>
    );
};
