import React from 'react';
import Select from 'react-select';

export interface TenantSelectorProps {
    tenants: any;
    isLoading: boolean;
    selectedTenant: number;
    onChange: any;
    value: any;
}

export const TenantSelectorComponent = ({
    tenants,
    isLoading,
    onChange,
    value,
}: TenantSelectorProps) => {
    return (
        <Select
            classNamePrefix="gk-tenant-select"
            options={tenants}
            isSearchable={false}
            isLoading={isLoading}
            onChange={onChange}
            value={value}
            isDisabled={tenants && tenants.length === 1}
        />
    );
};
