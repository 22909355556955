import React from 'react';
import { RenderPropSticky } from 'react-sticky-el';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '../../../components/buttons/Button';
import { TTutorial, Tutorial, TutorialStep } from '../../../models/Tutorials';
import { PageWrapper } from '../../PageWrapper';
import TenantContext from '../../../TenantContext';
import { GetResourceFromName } from '../../../models/ResourceFactory';
import { NotificationManager } from 'react-notifications';
import { TextInput } from '../../../components/forms/inputs/TextInput';
import { Section } from '../../../components/sections/Section';
import { LocalizedString } from '../../../models/LocalizedString';
import { RichTextEditor } from '../../../components/forms/richTextEditor/RichTextEditor';
import { LocalizedRichTextInput } from '../../../components/forms/inputs/LocalizedRichTextInput';

type EditTutorialState = {
    tutorial: TTutorial;
    loading: boolean;
    deleting: boolean;
    currentId: number;
};

interface EditTutorialProps extends RouteComponentProps {}

class _EditTutorialPage extends React.Component<
    EditTutorialProps,
    EditTutorialState
> {
    static contextType = TenantContext;
    constructor(props: EditTutorialProps) {
        super(props);

        let id = (props.match.params as any).id;
        if (id === 'new') {
            id = 0;
        } else {
            id = parseInt(id, 10);
        }

        this.state = {
            loading: true,
            deleting: false,
            tutorial: null,
            currentId: id,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeStep = this.handleOnChangeStep.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.handleAddNewStep = this.handleAddNewStep.bind(this);
    }

    async componentDidMount() {
        const tenant: any = _EditTutorialPage.contextType;
        const np = new Tutorial(tenant);
        const { currentId } = this.state;

        if (currentId === 0) {
            this.setState({ tutorial: np });
        } else {
            await this.loadData();
        }

        this.setState({ loading: false });
    }

    async componentDidUpdate(
        prevProps: EditTutorialProps,
        prevState: EditTutorialState
    ) {
        const { currentId } = this.state;
        if (currentId !== prevState.currentId) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });
        const { currentId } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('tutorials', this.context);

        np.Retrieve(currentId as number).then(() => {
            this.setState({ tutorial: np, loading: false });
        });
    }

    handleOnSave = async () => {
        const { tutorial } = this.state;
        this.setState({ loading: true });

        const response = await tutorial?.Save();
        this.setState({
            loading: false,
            currentId: response.ID,
            tutorial: { ...response },
        });
        this.props.history.replace('/tutorials/' + response.ID);
        NotificationManager.success('Save successfully');
    };

    handleOnDelete = async () => {
        const { tutorial } = this.state;
        this.setState({ deleting: true });

        const response = await tutorial?.Delete();
        this.setState({ deleting: false });
        this.props.history.replace('/tutorials');
        NotificationManager.success('Save successfully');
    };

    handleOnChange = (event: any, field: string) => {
        const { tutorial } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('tutorial', this.context, tutorial);
        const value =
            field !== 'Active'
                ? event?.currentTarget?.value ?? event
                : event?.currentTarget.checked;

        np.Set(field, value);
        this.setState({ tutorial: np });
    };

    handleAddNewStep = () => {
        const { tutorial } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('tutorial', this.context, tutorial);
        np.Steps = [
            ...np.Steps,
            {
                Name: '',
                Content: new LocalizedString(this.context),
            },
        ];

        this.setState({ tutorial: np });
    };

    handleOnChangeStep = (index: number, event: any, field: string) => {
        const { tutorial } = this.state;
        // @ts-ignore
        const np = new GetResourceFromName('tutorial', this.context, tutorial);

        if (field === 'Name' || field === 'Target') {
            np.Steps[index][field] = event?.currentTarget?.value;
        } else if (field === 'Content') {
            np.Steps[index].Content = event;
        }

        this.setState({ tutorial: np });
    };

    render() {
        const { tutorial, loading, deleting, currentId } = this.state;
        const id = currentId;
        const t = (t: string) => t;

        return (
            <PageWrapper title="">
                <RenderPropSticky
                    scrollElement=".gk-main-wrapper"
                    topOffset={0}
                    onFixedToggle={() => {
                        console.log('######## TOGGLE ');
                    }}
                >
                    {({
                        isFixed,
                        wrapperStyles,
                        wrapperRef,
                        holderStyles,
                        holderRef,
                    }: any) => (
                        <div ref={holderRef} style={holderStyles}>
                            <div
                                ref={wrapperRef}
                                style={{
                                    ...wrapperStyles,
                                    left: 226,
                                    width: 'calc(100%)',
                                }}
                                className={`w-full px-4  flex justify-between bg-white z-10 h-40${
                                    isFixed ? 'border py-4 shadow-xl' : 'my-4'
                                }`}
                            >
                                <div className={'font-bold uppercase'}>
                                    <Link to={`/tutorials`}>
                                        <span className="underline cursor-pointer text-gray-600 hover:opacity-60">
                                            tutorials
                                        </span>
                                    </Link>
                                    <span>{'  >  '}</span>
                                    {currentId > 0
                                        ? `tutorial #${currentId}`
                                        : `New tutorial`}
                                </div>
                                <div className={'flex'}>
                                    <Button
                                        className={'mr-4'}
                                        style={'danger'}
                                        text={'Delete'}
                                        disabled={loading || currentId === 0}
                                        onClick={this.handleOnDelete}
                                        loading={deleting}
                                    />
                                    <Button
                                        style={'primary'}
                                        text={'Save'}
                                        disabled={deleting}
                                        onClick={this.handleOnSave}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </RenderPropSticky>
                <form>
                    <div className={`${loading ? 'opacity-30' : ''}`}>
                        <Section
                            title={'General'}
                            collapsable={false}
                            defaultCollapsed={false}
                        >
                            <div className="w-full px-4 my-4 flex items-center">
                                <div className="w-full lg:w-6/12 px-4 mt-6">
                                    <TextInput
                                        type={'text'}
                                        label={'tutorial name'}
                                        value={tutorial?.Name ?? ''}
                                        onChange={(ev) =>
                                            this.handleOnChange(ev, 'Name')
                                        }
                                    />
                                </div>
                            </div>
                        </Section>
                        {tutorial?.Steps.map(
                            (step: TutorialStep, i: number) => {
                                return (
                                    <Section
                                        title={'Step ' + (i + 1)}
                                        collapsable={true}
                                        defaultCollapsed={false}
                                    >
                                        <div className="w-full px-4 my-4 flex items-center">
                                            <div className="w-full lg:w-6/12 px-4 mt-6">
                                                <TextInput
                                                    type={'text'}
                                                    label={'step name'}
                                                    value={step?.Name ?? ''}
                                                    onChange={(ev) =>
                                                        this.handleOnChangeStep(
                                                            i,
                                                            ev,
                                                            'Name'
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4 mt-6">
                                                <TextInput
                                                    type={'text'}
                                                    label={'step target'}
                                                    value={step?.Target ?? ''}
                                                    onChange={(ev) =>
                                                        this.handleOnChangeStep(
                                                            i,
                                                            ev,
                                                            'Target'
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full px-4 my-4 flex items-center">
                                            <div className="w-full lg:w-6/12 px-4 mt-6">
                                                <LocalizedRichTextInput
                                                    label={'step content'}
                                                    value={step?.Content ?? ''}
                                                    onChange={(ev) =>
                                                        this.handleOnChangeStep(
                                                            i,
                                                            ev,
                                                            'Content'
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Section>
                                );
                            }
                        )}
                        <Button
                            className={'ml-6 mt-8 text-green-600'}
                            text={'Add new step'}
                            onClick={this.handleAddNewStep}
                        />
                    </div>
                </form>
            </PageWrapper>
        );
    }
}

export const EditTutorialPage = withRouter(_EditTutorialPage);
