import React from 'react';

export const CollapseUp = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
    >
        <path d="M0 21l12-18 12 18z" />
    </svg>
);

export const CollapseDown = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
    >
        <path d="M24 3l-12 18-12-18z" />
    </svg>
);
