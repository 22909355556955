import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import { ResourceEditor, ResourceSettings } from './ResourceSettings';

export type TSlide = Slide | null;

export class Slide extends Resource {
    static Names = ['slide', 'slides'];
    Image: string = '';
    Content: LocalizedString;
    Order: number = 0;

    constructor(tenantId: string, slide?: any) {
        super(Slide.Names[1], tenantId, slide?.ID ?? 0);
        this.Content = new LocalizedString(tenantId);
        this.SetBody(slide);
    }

    GetNames(): string[] {
        return Slide.Names;
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: Slide.Names[1],
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Image',
                    field: 'Image',
                    sortable: false,
                    searchable: false,
                    type: 'image',
                },
                {
                    text: 'Order',
                    field: 'Order',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: 'Content',
                    field: 'Content',
                    sortable: false,
                    searchable: true,
                    type: 'localizedString',
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return (
                        resource?.Get('Image') !== '' ||
                        resource?.Get('Content') !== ''
                    );
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                field: 'Image',
                                type: 'image',
                                width: 2,
                            },
                            {
                                field: 'Order',
                                type: 'input',
                                data: 'number',
                                width: 2,
                            },
                            {
                                field: 'Visible',
                                type: 'checkbox',
                                width: 2
                            },
                            {
                                field: 'Content',
                                type: 'localizedRichText',
                                width: 12,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
