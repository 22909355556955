import React, { ChangeEvent, ChangeEventHandler } from 'react';

export interface TextInputProps {
    label?: string;
    type?: string;
    value?: string | number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

export const TextInput = ({
    label = '',
    type = 'text',
    value = '',
    onChange,
    disabled= false
}: TextInputProps) => {
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
    };

    return (
        <>
            {label && (
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left pt-2"
                    htmlFor="grid-name"
                >
                    {label}
                </label>
            )}
            <input
                id="grid-name"
                type={type}
                defaultValue={value}
                onChange={handleOnChange}
                disabled={disabled}
                className={`px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
            />
        </>
    );
};
