import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import { ResourceSettings } from './ResourceSettings';

export type TProduct = Product | null;

export enum BillingPeriod {
    BillingPeriodNone = 'NONE',
    BillingPeriodYearly = 'year',
    BillingPeriodMonthly = 'month',
    BillingPeriodWeekly = 'week',
    BillingPeriodDaily = 'day',
}

export interface Price {
    ID?: number;
    StripeID?: string;
    ProductID?: number;
    Active?: boolean;
    Amount?: number;

    Fee?: number;
    Currency?: string;
    Name?: string;
    Type?: string;
    Interval?: BillingPeriod;
    IsNew?: boolean;
    Expanded: boolean;

}

export class Product extends Resource {
    TenantID: number = 0;
    Name: string = '';
    Category: string = '';
    Sku: string = '';
    ImageUrl: string = '';
    Active: boolean = true;
    Content: string = '';
    Prices: Price[] = [];
    Description?: LocalizedString;
    Features?: LocalizedString;

    constructor(tenantId: string, prod?: any) {
        super('products', tenantId, prod?.ID ?? 0);

        this.SetBody(prod);
    }

    GetNames(): string[] {
        return ['product', 'products'];
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: 'products',
                filter: '',
            },
            columns: [
                {
                    text: 'Name',
                    field: 'Name',
                    sortable: true,
                    searchable: true,
                },

                { text: 'Sku', field: 'Sku', sortable: true, searchable: true },
                {
                    text: 'Created at',
                    field: 'CreatedAt',
                    type: 'datetime',
                    sortable: true,
                },
                {
                    text: 'Updated at',
                    field: 'UpdatedAt',
                    type: 'datetime',
                    sortable: true,
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
            ],
            bulkActions: [
                // {
                //     text: 'Add product',
                //     action: 'new',
                //     buttonClass: 'primary',
                // },
            ],
        };
    }
}
