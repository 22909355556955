import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import { ResourceSettings } from './ResourceSettings';

export type TSection = Section | null;

export class Section extends Resource {
    static Names = ['section', 'sections'];
    Name: string = '';
    BackgroundColor: string = '';
    LeftContent: LocalizedString;
    RightContent: LocalizedString;

    constructor(tenantId: string, section?: any) {
        super(Section.Names[1], tenantId, section?.ID ?? 0);
        this.LeftContent = new LocalizedString(tenantId);
        this.RightContent = new LocalizedString(tenantId);
        this.SetBody(section);
    }

    GetNames(): string[] {
        return Section.Names;
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: Section.Names[1],
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'LeftContent',
                    field: 'LeftContent',
                    sortable: false,
                    searchable: true,
                    type: 'localizedString',
                },
                {
                    text: 'RightContent',
                    field: 'RightContent',
                    sortable: false,
                    searchable: true,
                    type: 'localizedString',
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return (
                        resource?.Get('LeftContent') !== '' ||
                        resource?.Get('RightContent') !== ''
                    );
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                field: 'BackgroundColor',
                                type: 'color',
                                width: 12,
                            },
                            {
                                field: 'LeftContent',
                                type: 'localizedRichText',
                                width: 6,
                            },
                            {
                                field: 'RightContent',
                                type: 'localizedRichText',
                                width: 6,
                            },
                            {
                                field: 'LeftButton.Text',
                                type: 'localizedInput',
                                width: 3,
                            },
                            {
                                field: 'LeftButton.Url',
                                type: 'input',
                                width: 3,
                            },
                            {
                                field: 'RightButton.Text',
                                type: 'localizedInput',
                                width: 3,
                            },
                            {
                                field: 'RightButton.Url',
                                type: 'input',
                                width: 3,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
