import React, { useEffect, useState } from 'react';
import { LocalizedTextInput } from '../../forms/inputs/LocalizedTextInput';
import { LocalizedRichTextInput } from '../../forms/inputs/LocalizedRichTextInput';

export const RSEFLocalizedRichtext = ({ field, onChange, resource }: any) => {
    const [value, setValue] = useState();

    useEffect(() => {
        if (resource) {
            const aux = resource.Get(field.field);
            setValue(aux);
        }
    }, [resource]);

    const handleOnChange = (nv: any) => {
        onChange(nv, field.field);
    };

    return (
        <LocalizedRichTextInput
            label={field.name || field.field}
            value={value}
            onChange={handleOnChange}
        />
    );
};
