import React, { MouseEventHandler, MouseEvent } from 'react';
import { LoadingIcon } from '../icons';

export interface ButtonProps {
    text?: string;
    style?: string;
    disabled?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    loading?: boolean;
}

export const Button = ({
    style = '',
    text = '',
    disabled = false,
    loading = false,
    className = '',
    onClick,
}: ButtonProps) => {
    const handleOnClick: MouseEventHandler<HTMLButtonElement> = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        if (!disabled && !loading && onClick) {
            return onClick(event);
        }
    };

    return (
        <button
            className={`${className} ${style} ${
                disabled || loading ? 'disabled' : ''
            } flex items-center`}
            style={{ height: 40 }}
            onClick={handleOnClick}
        >
            <span>{text}</span>
            {loading && (
                <div style={{ fill: 'white', transform: 'scale(0.6)' }}>
                    <div className="animate-spin ml-2">{LoadingIcon}</div>
                </div>
            )}
        </button>
    );
};
