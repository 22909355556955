import React from 'react';
import {
    RSEFCheckbox,
    RSEFInput,
    RSEFJson,
    RSEFLocalizedInput,
    RSEFLocalizedRichtext,
    RSEFLocalizedTextArea,
    RSEFSelect,
    RSEFSeparator,
    RSEFImage,
    RSEFColor,
} from './fields';
import { RSEFCss } from './fields/RSEFCss';

export const ResourceEditorField = ({ field, resource, onChange }: any) => {
    switch (field.type) {
        case 'select':
            return (
                <RSEFSelect
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'checkbox':
            return (
                <RSEFCheckbox
                    field={field}
                    onChange={field.onChange || onChange}
                    resource={resource}
                />
            );
        case 'input':
            return (
                <RSEFInput
                    field={field}
                    onChange={onChange}
                    resource={resource}
                    type={field.data || 'text'}
                />
            );
        case 'json':
            return (
                <RSEFJson
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'css':
            return (
              <RSEFCss
                field={field}
                onChange={onChange}
                resource={resource}
              />
            );
        case 'localizedInput':
            return (
                <RSEFLocalizedInput
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'localizedTextArea':
            return (
                <RSEFLocalizedTextArea
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'localizedRichText':
            return (
                <RSEFLocalizedRichtext
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'separator':
            return <RSEFSeparator />;
        case 'image':
            return (
                <RSEFImage
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
        case 'color':
            return (
                <RSEFColor
                    field={field}
                    onChange={onChange}
                    resource={resource}
                />
            );
    }

    return null;
};
