import { Resource } from './Resource';
import { LocalizedString } from './LocalizedString';
import { ResourceSettings } from './ResourceSettings';

export type TFeature = Feature | null;

export class Feature extends Resource {
    static Names = ['feature', 'features'];
    Name: string = '';
    Content: LocalizedString;
    Order: number = 0;
    Image: string = '';
    Visible: boolean = false;
    ClassName: string = '';
    Button: {
        Text: LocalizedString;
        Url: string;
    };

    constructor(tenantId: string, feature?: any) {
        super(Feature.Names[1], tenantId, feature?.ID ?? 0);
        this.Content = new LocalizedString(tenantId);
        this.Button = {
            Text: new LocalizedString(tenantId),
            Url: '',
        };
        this.SetBody(feature);
    }

    GetNames(): string[] {
        return Feature.Names;
    }

    GetResourceSettings(): ResourceSettings {
        return {
            checks: true,
            resource: {
                name: Feature.Names[1],
                filter: '',
            },
            columns: [
                { text: '#', field: 'ID', sortable: true },
                {
                    text: 'Group',
                    field: 'Group',
                    sortable: true,
                    searchable: true,
                },
                {
                    text: 'Order',
                    field: 'Order',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: 'Image',
                    field: 'Image',
                    sortable: false,
                    searchable: false,
                    type: 'image',
                },
                {
                    text: 'Content',
                    field: 'Content',
                    sortable: false,
                    searchable: true,
                    type: 'localizedString',
                },
                { text: '', field: 'actions' },
            ],
            actions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    color: 'text-green-600',
                    icon: 'fal fa-edit',
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    color: 'text-red-600',
                    icon: 'fal fa-trash',
                },
            ],
            bulkActions: [
                {
                    text: 'Delete',
                    action: 'delete',
                    buttonClass: 'danger',
                },
                {
                    text: 'Add new',
                    action: 'new',
                    buttonClass: 'primary',
                },
            ],
            editor: {
                isValid: (resource: Resource | null) => {
                    return resource?.Get('Content') !== '';
                },
                sections: [
                    {
                        title: 'General',
                        collapsable: false,
                        defaultCollapsed: false,
                        fields: [
                            {
                                field: 'Image',
                                type: 'image',
                                width: 2,
                            },
                            {
                                field: 'Order',
                                type: 'input',
                                data: 'number',
                                width: 2,
                            },
                            {
                                field: 'Group',
                                type: 'input',
                                width: 2,
                            },
                            {
                                field: 'ClassName',
                                type: 'input',
                                width: 2,
                            },
                            {
                                field: 'Visible',
                                type: 'checkbox',
                                width: 2
                            },
                            {
                                field: 'Content',
                                type: 'localizedRichText',
                                width: 12,
                            },
                            {
                                field: 'Button.Text',
                                type: 'localizedInput',
                                width: 6,
                            },
                            {
                                field: 'Button.Url',
                                type: 'input',
                                width: 6,
                            },
                        ],
                    },
                ],
            },
        };
    }
}
